import { useAuth0 } from "@auth0/auth0-react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, List, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import {  usePageTitle, useGetUnauthedSigning, useContent } from '../../logic';
import { getPageUrl } from "../../utils";
import { DoubleCloudBox, ErrorDialog, LoadingSignatureIframe, ESignProviders, needsSigningErrors } from '../shared';
import { Help } from "../shared/Help";
import { Legalese } from "../shared/Legalese";
import { SignatureHelp } from "../shared/SignatureHelp";
import CloseIcon from '@mui/icons-material/Close';
import { NavLink } from "react-router-dom";
import { EsignDisclosureAgreement } from "../shared/EsignDisclosureAgreement";


export function UnauthedSigningPage({ drawerHeight }: {
  drawerHeight: number;
}) {
  usePageTitle('Welcome!');

  const { signingHash = '' } = useParams()
  const { isAuthenticated } = useAuth0();

  const theme = useTheme() as any
  const primaryColor = theme.palette.primary.main
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const { ClosingsText } = useContent()

  const {
    requestSigningUrl,
    resetRequestState,
    loading, error, data
  } = useGetUnauthedSigning(getPageUrl(), signingHash)

  const [signingUrl, setSigningUrl] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [pin, setPin] = useState('')
  const [isSigningIframeLoading, setIsSigningIframeLoading] = useState(false);
  const [showHelp, setShowHelp] = useState(false)
  const [open, setOpen] = useState(false)
  const [userCompletedSigning, setUserCompletedSigning] = useState(false)
  const [showErrorLoadingSigning, setShowErrorLoadingSigning] = useState(false)
  const [needsToAgree, setNeedsToAgree] = useState(false)


  const eSignProvider = data?.ESignProvider
  const isDropboxSigning = eSignProvider === 'DropboxSign'
  const esignDisclosureHtml = data?.esignDisclosureHtml
  const signingCompletedErrorResponse = data?.errorMessage === needsSigningErrors.signingCompleted

  useEffect(() => {
    if (data?.url && !signingUrl) {
      setSigningUrl(data.url)
      setOpen(true)
      setIsSigningIframeLoading(true)
    } else if (signingCompletedErrorResponse) {
      setIsSigningIframeLoading(false)
      setUserCompletedSigning(true)
      setOpen(true)
    }

    if (data?.esignDisclosureHtml) {
      setNeedsToAgree(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  function onAgree() {
    setNeedsToAgree(false)
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  function onSigningDialogClose() {
    setOpen(false)
  }

  function resetComponentState() {
    resetRequestState()
    setSigningUrl('')
    setIsSigningIframeLoading(false)
    setShowErrorLoadingSigning(false)
    setUserCompletedSigning(false)
  }

  const showSigningDialog = signingUrl || userCompletedSigning
  const showErrorDialog = (error || showErrorLoadingSigning) && !userCompletedSigning
  const showLoadingSignatureIframe = !userCompletedSigning && !showErrorDialog && (isSigningIframeLoading || loading)
  const startLoadingIframe = !userCompletedSigning && signingUrl
  const showCompletedMessage = userCompletedSigning

  const showHavingTroubleButton = !isDropboxSigning && signingUrl && !isSigningIframeLoading
  const showCloseButton = userCompletedSigning


  // TODO: if logged in include close X in title
  return <>
    <Box sx={{
      maxWidth: '450px', ml: 'auto', mr: 'auto',
      mt: { xs: 0, sm: `${drawerHeight}px` },
    }}>
      <Box sx={{
        display: 'flex', pr: '20px', pl: '20px', pt: 3,
        justifyContent: 'space-between',
      }}>
        <Box sx={{
          fontSize: { xs: '46px', sm: '50px' },
          lineHeight: '54px',
          fontWeight: 300,
          minWidth: 220,
          pt: { xs: '26px', sm: '44px' },
          pl: 1,
          mb: 2,
          mr: '10px',
          letterSpacing: '-0.5px',
        }}>
          Welcome <br/>to your <br/>e-signing
        </Box>
        <DoubleCloudBox></DoubleCloudBox>
      </Box>
      <List sx={{
        width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', mt: 3,
      }}>
        { !signingUrl ? <>
          <Typography sx={{
            fontSize: 26,
            fontWeight: 300,
            lineHeight: '36px',
            letterSpacing: '-0.5px',
            mb: 2, ml: 4,
            alignSelf: 'start'
          }}>
            Please enter your pin
          </Typography>
          <Box sx={{
            width: '90%', maxWidth: '500px',
            alignSelf: 'center',
          }}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                resetComponentState()
                requestSigningUrl(pin)}
              }
            >
              <TextField
                required
                id="outlined-required"
                label="Pin"
                sx={{ width: '100%' }}
                value={pin}
                onChange={(e) => setPin(e.target.value)}
                autoComplete="pin"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  autoFocus: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((show) => !show)}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Typography sx={{
                fontSize: 'smaller',
                fontWeight: 400,
                lineHeight: '20px',
                letterSpacing: '0.1px',
                mt: 1, ml: 1,
              }}>
                Your pin will have accompanied the link to this page
              </Typography>

              <LoadingButton
                loading={loading}
                type="submit"
                variant="contained"
                sx={{
                  width: '100%', height: '42px', boxShadow: 'none',
                  fontSize: '16px', fontWeight: '400', mt: 3, mb: 6,
                  bgcolor: 'primary.main', color: 'white', border: 'none',
                }}
              >
                Submit
              </LoadingButton>
            </form>
          </Box>
        </> : <>
          <Box sx={{
            display: 'flex', flexDirection: 'column', alignItems: 'center',
            mt: 3, mb: 7, width: '90%',
          }}>
            <Button
              variant='contained'
              sx={{
                width: '100%',
              }}
              onClick={() => {
                resetComponentState()
                requestSigningUrl(pin)
              }}
            >
              Open your e-signing
            </Button>
            {isAuthenticated ?
              <Button
                component={NavLink}
                to="/closings"
                sx={{
                  width: '100%',
                  mt: 1,
                }}
              >
                View your {ClosingsText}
              </Button> : ''

            }
          </Box>
        </>}

        <Help />

        {(isSmall || !isAuthenticated) ?
          <Box sx={{ pt: 1 }}>
            <Legalese></Legalese>
          </Box>
          : ''
        }
      </List>
    </Box>

    {showSigningDialog ?
      <Dialog
        open={open}
        fullScreen={isSmall ? true : false}
        disableEscapeKeyDown={true}
        sx={{
          height: '100%',
          padding: { xs: '8px' },
          '& .MuiDialog-paper': { borderRadius: '20px', height: '100%' }
        }}
        fullWidth={true}
        maxWidth='xl'
      >
        <DialogTitle sx={{
          'borderBottom': `1px solid ${primaryColor}`,
          display: 'flex', alignItems: 'center',
          justifyContent: 'end',
          height: '55px',
        }}>
          <IconButton
            aria-label="close"
            onClick={() => {
              setOpen(false)
            }}
            sx={{
              position: 'absolute',
              right: 8,
              top: 10,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {showLoadingSignatureIframe && <LoadingSignatureIframe />}

          {needsToAgree && esignDisclosureHtml &&
            <EsignDisclosureAgreement esignDisclosureHtml={esignDisclosureHtml!} onAgree={onAgree} />
          }

          {startLoadingIframe &&
            <ESignProviders
              isSigningIframeLoading={isSigningIframeLoading}
              setIsSigningIframeLoading={setIsSigningIframeLoading}
              eSignProvider={eSignProvider}
              signingUrl={signingUrl}
              onSigningDialogClose={onSigningDialogClose}
              setUserCompletedSigning={setUserCompletedSigning}
              setShowErrorLoadingSigning={setShowErrorLoadingSigning}
              needsToAgree={needsToAgree}
            />
          }

          {showCompletedMessage &&
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 3, mb: 3 }}>
              <Alert severity="success" sx={{ fontSize: 'medium' }}>
                You have completed e-signing this document!
              </Alert>
            </Box>
          }

        </DialogContent>
        <DialogActions
          sx={{
            alignItems: 'center',
            mb: 1, mr: 1,
          }}
        >
          {showHavingTroubleButton && <Button
            onClick={() => setShowHelp(!showHelp)}
            sx={{
              display: 'flex',
              minWidth: '100px',
            }}
          >Having trouble?</Button>}

          {showCloseButton && <Button
            onClick={onSigningDialogClose}
            variant="contained"
            sx={{
              display: 'flex',
              minWidth: '100px',
            }}
          >Close</Button>}
        </DialogActions>
      </Dialog> : ''
    }


    {/* HELP DIALOG */}
    <SignatureHelp open={showHelp} setOpen={setShowHelp} isSmall={isSmall} />

    {/* ERROR DIALOG*/}
    {showErrorDialog && (
      // Using empty try again func to just close the dialog. In this case, trying
      // the same req again doesn't make sense, nor does reloading the page so it's
      // just a close button so they can double check their pin etc.
      <ErrorDialog onClose={() => resetComponentState()} onTryAgain={() => {} } tryAgainText="Close">
        <>
          <Typography component="span" sx={{ display: 'block' }}>
            There was an error processing your request:
          </Typography>
          <Typography component="span" sx={{ mb: 2, fontWeight: 'bold', display: 'block' }}>
            {error || 'Unknown error'}
          </Typography>
          <Typography component="span" sx={{ display: 'block' }}>
            Please double check your pin and try again or contact us for assistance.
          </Typography>
        </>
      </ErrorDialog>
    )}

  </>
}
