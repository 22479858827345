import { Box, Typography, Button, FormControlLabel, Checkbox, Dialog, DialogContent, DialogActions } from "@mui/material";
import { useState } from "react";
import { SanitizedHtml } from "./SanitizedHtml";

export function EsignDisclosureAgreement({ esignDisclosureHtml, onAgree }: {
  esignDisclosureHtml: string
  onAgree: () => void
}) {
  const [openDisclosure, setOpenDisclosure] = useState(false)

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100% ' }}>
      <Typography variant='h6' sx={{ mt: 3, mb: 2 }} >

        Please read the&nbsp;
        <Button
          variant="text"
          onClick={() => setOpenDisclosure(true)}
          sx={{ textTransform: 'none', padding: 0, fontSize: '1.2rem' }}
        >
          Electronic Record and Signature Disclosure.
        </Button>
      
        <br/>
        <FormControlLabel
          control={<Checkbox name="agree" />}
          label={
            <Typography variant="body1" sx={{ fontSize: '1.2rem' }}>
              I agree to use electronic records and signatures
            </Typography>
          }
          onChange={(e) => onAgree()}
        />

        <Dialog
          open={openDisclosure}
          onClose={() => setOpenDisclosure(false)}
          fullWidth
          maxWidth="md"
        >
          <DialogContent>
            <SanitizedHtml html={esignDisclosureHtml} />
          </DialogContent>

          <DialogActions>
            <Button
              sx={{ fontSize: '1.1rem' }}
              onClick={() => setOpenDisclosure(false)}>
              Close
            </Button>
            <Button
              sx={{ fontSize: '1.1rem' }}
              onClick={() => {
                const printWindow = window.open('', '_blank');
                printWindow?.document.write(esignDisclosureHtml);
                printWindow?.document.close();
                printWindow?.print();
              }}
            >
              Print
            </Button>
            <Button
              sx={{ fontSize: '1.1rem' }}
              onClick={() => {
                const blob = new Blob([esignDisclosureHtml], { type: 'text/html' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = 'esign-disclosure.html';
                link.click();
              }}
            >
              Download
            </Button>
          </DialogActions>
        </Dialog>

      </Typography>
    </Box>
  )
}