import { PortalMfaValues } from "../types"

const onceRestrictedMfaString = 'once-restricted'

export function getScopes(MfaRequired?: PortalMfaValues) {
  const isOnceRestrictedMfa = MfaRequired === onceRestrictedMfaString

  if (isOnceRestrictedMfa) {
    return 'enroll remove:authenticators read:authenticators'
  } else {
    return ''
  }
}

export function getAudience(MfaRequired?: PortalMfaValues) {
  const isOnceRestrictedMfa = MfaRequired === onceRestrictedMfaString

  if (isOnceRestrictedMfa) {
    return `https://${process.env.REACT_APP_AUTH0_DOMAIN_FOR_MFA_CLAIM!}/mfa/`
  } else {
    return `https://${process.env.REACT_APP_AUTH0_DOMAIN_FOR_MFA_CLAIM!}/api/v2/`
  }
}
